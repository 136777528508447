import * as PIXI from "pixijs";
import Menu from "./Menu";
import Label from "../components/Label";
import { MenuType } from "../../types";
import { gameFont } from "../style";
export default class InGameMenu extends Menu {
    constructor(renderer) {
        super();
        this.renderer = renderer;
        this.initListeners();
    }
    init(game) {
        this.game = game;
        // Game Frame
        this.gameFrame = new PIXI.Container();
        this.addChild(this.gameFrame);
        var player = this.game.player;
        const barMargin = 30;
        // HP Bar
        const hpbarWidth = 450;
        const hpbarHeight = 25;
        this.addChild(bar(barMargin, barMargin, hpbarWidth, hpbarHeight, {
            label: "生命:",
            value: player.health,
            maxValue: player.maxHealth,
            background: 0xf01f1f
        }));
        // Defense Bar
        const defensebarWidth = 450;
        const defensebarHeight = 25;
        this.addChild(bar(barMargin, hpbarHeight + barMargin * 2, defensebarWidth, defensebarHeight, {
            label: "防御:",
            value: player.defense,
            maxValue: player.maxDefense,
            background: 0xbbbbbb
        }));
    }
    initListeners() {
        document.addEventListener("keydown", (e) => {
            if (e.key === "Escape" && this.renderer.currentMenu === MenuType.INGAME) {
                this.game.saveProgress();
                this.renderer.setMenu(MenuType.MAIN);
            }
        });
    }
    update(delta) {
        this.game.update(delta, this.gameFrame);
    }
}
function bar(x, y, width, height, option) {
    var container = new PIXI.Container();
    const padding = 5;
    var barLabel = new Label(option.label, {
        x: 0,
        y: 0,
        style: {
            fill: 0xffffff,
            fontSize: 16,
            fontFamily: gameFont
        }
    });
    barLabel.position.x = x;
    barLabel.position.y = y + padding;
    barLabel.appendTo(container);
    var barBorder = new PIXI.Graphics();
    barBorder.lineStyle(4, 0xffffff);
    barBorder.drawRect(x + barLabel.textObject.width + 15, y, width, height);
    container.addChild(barBorder);
    var bar = new PIXI.Graphics();
    bar.beginFill(option.background);
    bar.drawRect(x + barLabel.textObject.width + 15 + padding, y + padding, (width - 2 * padding) * (option.value / option.maxValue), height - 2 * padding);
    container.addChild(bar);
    var barValue = new Label(option.value + "/" + option.maxValue, {
        x: 0,
        y: 0,
        style: {
            fill: 0xffffff,
            fontSize: 16,
            fontFamily: gameFont
        }
    });
    barValue.position.x = x + barLabel.textObject.width + 15 + width / 2 - barValue.textObject.width / 2;
    barValue.position.y = y + padding;
    barValue.appendTo(container);
    return container;
}
