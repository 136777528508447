import * as PIXI from "pixijs";
import Component from "./Component";
export default class Button extends Component {
    constructor(option) {
        super(option);
        this.background = new PIXI.Graphics();
        this.option = option;
        if (!this.option.style.borderColor)
            this.option.style.borderColor = this.option.style.backgroundColor;
        this.interactive = true;
        this.on("click", (e) => {
            if (option.onClick)
                option.onClick(e);
            this.cursor = "default";
        });
        this.on("mouseenter", () => {
            if (option.style.hoverBackgroundColor)
                this.setBackgroundColor(option.style.hoverBackgroundColor);
            if (option.style.hoverBorderColor)
                this.setBorderColor(option.style.hoverBorderColor);
            this.cursor = "pointer";
        });
        this.on("mouseleave", () => {
            this.setBackgroundColor(option.style.backgroundColor);
            this.setBorderColor(option.style.borderColor);
            this.cursor = "default";
        });
        this.setBackgroundColor(option.style.backgroundColor);
        this.setBorderColor(option.style.borderColor);
        this.addChild(this.background);
        this.textObject = new PIXI.Text(option.text, option.textStyle);
        this.updateTextPosition();
        this.addChild(this.textObject);
    }
    setBackgroundColor(color) {
        this.background.beginFill(color);
        this.background.drawRect(this.option.x, this.option.y, this.option.width, this.option.height);
    }
    setBorderColor(color) {
        this.background.lineStyle(2, color);
        this.background.drawRect(this.option.x, this.option.y, this.option.width, this.option.height);
    }
    setText(text) {
        this.textObject.text = text;
    }
    updateTextPosition() {
        this.textObject.x = this.option.x + (this.option.width / 2) - (this.textObject.width / 2);
        this.textObject.y = this.option.y + (this.option.height / 2) - (this.textObject.height / 2);
    }
}
