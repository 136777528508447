import * as PIXI from "pixijs";
import Player from "./player/Player";
import Utils from "../../utils/Utils";
import { Towards, EntityAnimation } from "../../types";
import { g } from "../../global";
class Entity extends PIXI.Sprite {
    constructor(x, y, towards) {
        super();
        this.isWalking = false;
        this.haveGravity = true;
        this.frameIndex = 0;
        this.speed = 0; // y axis
        this.x = x;
        this.y = y;
        this.towards = towards;
    }
    /**
     * Set the state of player to walking
     */
    walk(towards) {
        this.isWalking = true;
        this.towards = towards;
        this.playAnimation(EntityAnimation.WALKING);
    }
    stopWalking() {
        this.isWalking = false;
        this.playAnimation(EntityAnimation.STANDING);
    }
    registerAnimationTimer() {
        this.animation = setInterval(() => {
            this.texture = this.frameList[this.frameIndex];
            this.frameIndex++;
            if (this.frameIndex === this.frameList.length) {
                this.frameIndex = 0;
            }
        }, Entity.delta);
    }
    stopAnimation() {
        clearInterval(this.animation);
        this.animation = null;
        this.frameIndex = 0;
    }
    get towards() {
        return this._towards;
    }
    set towards(value) {
        if ((value === Towards.LEFT && this._towards === undefined) ||
            (value === Towards.LEFT && this._towards === Towards.RIGHT)) {
            // reverse
            this.anchor.x = 1;
            this.scale.x *= -1;
        }
        else if (value === Towards.RIGHT && this._towards === Towards.LEFT) {
            // revert
            this.anchor.x = 0;
            this.scale.x /= -1;
        }
        this._towards = value;
    }
    update(delta) {
        // Gravity
        var collision = Utils.containInScreen(this);
        if (this.haveGravity &&
            (!collision ||
                (collision && !collision.has("bottom")))) {
            this.speed += g * delta;
            const dy = this.speed * delta + g * Math.pow(delta, 2) / 2;
            // To prevent the player sprite being "stuck" in the ground
            if (this.y + dy > window.innerHeight - this.height) {
                this.y = window.innerHeight - this.height;
                if (this instanceof Player) {
                    this.canJump = true;
                    this.jumpingTimes = 0;
                }
            }
            else {
                this.y += dy;
            }
        }
        // Walking
        if (this.isWalking) {
            switch (this.towards) {
                case Towards.LEFT:
                    this.x -= 5;
                    break;
                case Towards.RIGHT:
                    this.x += 5;
                    break;
            }
        }
    }
}
Entity.delta = 200; // ms
export default Entity;
