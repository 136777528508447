import Button from "./Button";
export default class Switcher extends Button {
    constructor(option) {
        super(Object.assign(Object.assign({}, option), { text: option.values[option.defaultIndex || 0], onClick: () => this.handleClick() }));
        this._value = "";
        this.changeHandler = null;
        this.values = option.values;
        this.index = option.defaultIndex || 0;
        if (option.onChange)
            this.changeHandler = option.onChange;
    }
    handleClick() {
        if (this.index < this.values.length - 1) {
            this.index++;
            return;
        }
        this.index = 0;
    }
    set index(index) {
        this._value = this.values[index];
        this.textObject.text = this._value;
        this.updateTextPosition();
        if (this.changeHandler)
            this.changeHandler(this._value);
    }
    get index() {
        return this.values.indexOf(this._value);
    }
}
