export var MenuType;
(function (MenuType) {
    MenuType[MenuType["LOADING"] = 0] = "LOADING";
    MenuType[MenuType["MAIN"] = 1] = "MAIN";
    MenuType[MenuType["SAVES"] = 2] = "SAVES";
    MenuType[MenuType["SETTINGS"] = 3] = "SETTINGS";
    MenuType[MenuType["ABOUT"] = 4] = "ABOUT";
    MenuType[MenuType["INGAME"] = 5] = "INGAME";
    MenuType[MenuType["LOGIN"] = 6] = "LOGIN";
})(MenuType || (MenuType = {}));
export var Skin;
(function (Skin) {
    Skin["TEO"] = "Teo";
    Skin["KAYCE"] = "Kayce";
})(Skin || (Skin = {}));
export var Towards;
(function (Towards) {
    Towards[Towards["LEFT"] = 0] = "LEFT";
    Towards[Towards["RIGHT"] = 1] = "RIGHT";
})(Towards || (Towards = {}));
export var EntityAnimation;
(function (EntityAnimation) {
    EntityAnimation[EntityAnimation["STANDING"] = 0] = "STANDING";
    EntityAnimation[EntityAnimation["WALKING"] = 1] = "WALKING";
    EntityAnimation[EntityAnimation["SKILL1"] = 2] = "SKILL1";
    EntityAnimation[EntityAnimation["SKILL2"] = 3] = "SKILL2";
    EntityAnimation[EntityAnimation["ACTIVATED"] = 4] = "ACTIVATED";
    EntityAnimation[EntityAnimation["ANGRY"] = 5] = "ANGRY";
})(EntityAnimation || (EntityAnimation = {}));
