class KeyBind {
    constructor(key, keypressListener, keyupListener) {
        this.canCallListener = true;
        this.pressed = false;
        this.key = key;
        this.listener = () => {
            if (!this.canCallListener)
                return;
            this.canCallListener = false;
            keypressListener();
        };
        document.addEventListener("keypress", (e) => {
            if (this.pressed)
                return;
            if (e.key.toLowerCase() === key)
                this.pressed = true;
        });
        document.addEventListener("keyup", (e) => {
            if (e.key.toLowerCase() !== key)
                return;
            if (keyupListener)
                keyupListener();
            this.canCallListener = true;
            this.pressed = false;
        });
        window.addEventListener("beforeunload", () => {
            this.canCallListener = true;
            this.pressed = false;
        });
    }
    static create(key, keypressListener, keyupListener) {
        var instance = new KeyBind(key, keypressListener, keyupListener);
        KeyBind.bindList.push(instance);
        return instance;
    }
}
KeyBind.bindList = [];
export default KeyBind;
