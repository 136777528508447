import * as PIXI from "pixijs";
import Component from "./Component";
import { gameFont } from "../style";
const tooltipPadding = 7;
export default class Label extends Component {
    constructor(text, option, tooltip) {
        super(option);
        this.textObject = new PIXI.Text(text, option.style);
        this.textObject.x = option.x / 2;
        this.textObject.y = option.y / 2;
        this.textObject.interactive = true;
        this.textObject.on("mouseenter", (e) => {
            if (!tooltip)
                return;
            this.tooltipObject.visible = true;
            this.updateTooltipObject(e.client.x - this.position.x + 15, e.client.y - this.position.y - 20);
        });
        this.textObject.on("mousemove", (e) => {
            if (!tooltip)
                return;
            this.updateTooltipObject(e.client.x - this.position.x + 15, e.client.y - this.position.y - 20);
        });
        this.textObject.on("mouseleave", () => {
            if (!tooltip)
                return;
            this.tooltipObject.visible = false;
            this.tooltipObject.clear();
        });
        this.addChild(this.textObject);
        if (tooltip) {
            this.tooltipObject = new PIXI.Graphics();
            this.tooltipObject.visible = false;
            this.addChild(this.tooltipObject);
            this.tooltipLabel = new PIXI.Text(tooltip, {
                fill: 0xdddddd,
                fontSize: 16,
                fontFamily: gameFont
            });
            this.tooltipObject.addChild(this.tooltipLabel);
        }
    }
    setText(text) {
        this.textObject.text = text;
    }
    updateTooltipObject(x, y) {
        this.tooltipObject.clear();
        this.tooltipObject.beginFill(0x000000);
        this.tooltipObject.drawRoundedRect(x, y, this.tooltipLabel.width + 2 * tooltipPadding, this.tooltipLabel.height + 2 * tooltipPadding, 2);
        this.tooltipLabel.position.x = x + tooltipPadding;
        this.tooltipLabel.position.y = y + tooltipPadding;
    }
}
