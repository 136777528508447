import { Skin } from "../types";
import Storage from "../utils/Storage";
import Utils from "../utils/Utils";
const storageKey = "pw.settings";
class Settings {
    constructor() {
        this.settings = new Map(Storage.get().getItem(storageKey, Utils.mapToArray(Settings.defaultSettings)));
    }
    static get() {
        if (!Settings.instance)
            Settings.instance = new Settings();
        return Settings.instance;
    }
    getValue(key) {
        return this.settings.get(key);
    }
    setValue(key, value) {
        this.settings.set(key, value);
        Storage.get().setItem(storageKey, Utils.mapToArray(this.settings));
    }
}
Settings.defaultSettings = new Map([
    ["player.skin", Skin.TEO]
]);
export default Settings;
