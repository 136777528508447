import { EventEmitter } from "events";
export default class Emitter extends EventEmitter {
    static get() {
        if (!Emitter.instance) {
            Emitter.instance = new Emitter();
            Emitter.instance.setMaxListeners(Infinity);
        }
        return Emitter.instance;
    }
}
