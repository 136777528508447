import * as PIXI from "pixijs";
export default class Menu extends PIXI.Container {
    constructor() {
        super();
        this.width = window.innerWidth;
        this.height = window.innerHeight;
    }
    appendTo(container) {
        container.addChild(this);
    }
    removeFrom(container) {
        container.removeChild(this);
    }
}
