import * as PIXI from "pixijs";
import Player from "./Player";
import { Skin, EntityAnimation } from "../../../types";
const standingAnimation = [
    PIXI.Texture.from("/assets/textures/player/kayce/standing/1.png"),
    PIXI.Texture.from("/assets/textures/player/kayce/standing/2.png"),
    PIXI.Texture.from("/assets/textures/player/kayce/standing/3.png"),
    PIXI.Texture.from("/assets/textures/player/kayce/standing/4.png"),
    PIXI.Texture.from("/assets/textures/player/kayce/standing/5.png"),
    PIXI.Texture.from("/assets/textures/player/kayce/standing/6.png"),
    PIXI.Texture.from("/assets/textures/player/kayce/standing/7.png"),
];
const walkingAnimation = [
    PIXI.Texture.from("/assets/textures/player/kayce/walking/1.png"),
    PIXI.Texture.from("/assets/textures/player/kayce/walking/2.png"),
    PIXI.Texture.from("/assets/textures/player/kayce/walking/3.png"),
    PIXI.Texture.from("/assets/textures/player/kayce/walking/4.png"),
    PIXI.Texture.from("/assets/textures/player/kayce/walking/5.png"),
    PIXI.Texture.from("/assets/textures/player/kayce/walking/6.png"),
];
export default class Kayce extends Player {
    constructor(x, y, towards) {
        super(x, y, towards);
        this.width = 103;
        this.height = 176;
        this.playAnimation(EntityAnimation.STANDING);
    }
    getSkin() {
        return Skin.KAYCE;
    }
    useSkill1() {
    }
    useSkill2() {
    }
    playAnimation(animation) {
        this.stopAnimation();
        switch (animation) {
            case EntityAnimation.STANDING:
                this.frameList = standingAnimation;
                break;
            case EntityAnimation.WALKING:
                this.frameList = walkingAnimation;
                break;
        }
        this.registerAnimationTimer();
    }
    update(delta) {
        super.update(delta);
    }
}
