import * as PIXI from "pixijs";
export default class Component extends PIXI.Sprite {
    constructor(option) {
        super();
        this.x = option.x;
        this.y = option.y;
    }
    appendTo(container) {
        container.addChild(this);
    }
}
