import Entity from "../Entity";
import { g } from "../../../global";
class Player extends Entity {
    constructor(x, y, towards) {
        super(x, y, towards);
        this.isUsingSkill = false;
        this.isJumping = false;
        this.canJump = true;
        this.jumpingHeight = 0;
        this.jumpingTimes = 0; // <= 2
        this.maxHealth = 200;
        this.health = 200;
        this.maxDefense = 10;
        this.defense = 0;
    }
    jump() {
        if (!this.canJump)
            return;
        this.jumpingTimes++;
        if (this.jumpingTimes > 2) {
            this.jumpingTimes = 0;
            this.canJump = false;
            return;
        }
        this.isJumping = true;
        this.haveGravity = false;
        this.speed = Math.sqrt(2 * g * Player.maxJumpingHeight);
    }
    update(delta) {
        super.update(delta);
        // Jumping
        if (this.isJumping) {
            this.speed -= g * delta;
            const dy = this.speed * delta + g * Math.pow(delta, 2) / 2;
            this.y -= dy;
            this.jumpingHeight += dy;
            if (this.speed <= 0) {
                this.isJumping = false;
                this.haveGravity = true;
                this.jumpingHeight = 0;
                this.speed = 0;
            }
        }
    }
}
Player.maxJumpingHeight = 240;
export default Player;
