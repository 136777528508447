import * as PIXI from "pixijs";
import Bump from "../lib/bump.js";
class Utils {
    static getRandom(min, max) {
        return Math.floor(Math.random() * (max - min + 1) + min);
    }
    static timeToString(time) {
        var date = new Date(time);
        return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}-${date.getHours() < 10 ? "0" + date.getHours() : date.getHours()}:${date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()}:${date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds()}`;
    }
    static containInScreen(sprite) {
        return new Bump(PIXI).contain(sprite, { x: -Infinity, y: 0, width: Infinity, height: window.innerHeight }, false);
    }
    static throttle(cb, delay) {
        var timer = null;
        return (...args) => {
            if (timer)
                clearTimeout(timer);
            timer = setTimeout(() => {
                cb(...args);
            }, delay);
        };
    }
    static getId() {
        Utils.currentId++;
        return Utils.currentId;
    }
    static mapToArray(map) {
        var result = [];
        map.forEach((value, key) => {
            result.push([key, value]);
        });
        return result;
    }
}
Utils.currentId = 0;
export default Utils;
