import Storage from "../utils/Storage";
import { Towards } from "../types";
export default class Save {
    constructor(raw) {
        var _a;
        this.player = null;
        this.id = raw.id;
        this.time = raw.time;
        this.player = (_a = raw.player) !== null && _a !== void 0 ? _a : {
            x: 10,
            y: 30,
            towards: Towards.RIGHT
        };
    }
    static create() {
        var newSave = new Save({
            id: Save.getSaves().length + 1,
            time: new Date().getTime(),
            player: null
        });
        Storage.get().setItem("pw.saves", [...Save.getSaves(), newSave.toRaw()]);
        return newSave;
    }
    static getSaves() {
        return Storage.get().getItem("pw.saves", []);
    }
    saveToLocal() {
        var saves = Save.getSaves();
        for (let i = 0; i < saves.length; i++) {
            if (saves[i].id === this.id) {
                saves[i] = this.toRaw();
                break;
            }
        }
        Storage.get().setItem("pw.saves", saves);
    }
    toRaw() {
        return {
            id: this.id,
            time: this.time,
            player: this.player
        };
    }
}
