// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/fonts/Minecraft AE.woff", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
    margin: 0;
    padding: 0;
    overflow: hidden;
}

@font-face {
    font-family: Minecraft AE;
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format(woff);
    font-weight: 400;
    font-style: normal;
}

* {
    /* It is quite odd that if there's not the following declaration, the font won't be available in the canvas. */
    /* So DO NOT DELETE THIS LINE */
    font-family: Minecraft AE;
}
`, "",{"version":3,"sources":["webpack://./src/style/style.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,UAAU;IACV,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;IACzB,yDAA8D;IAC9D,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,8GAA8G;IAC9G,+BAA+B;IAC/B,yBAAyB;AAC7B","sourcesContent":["body {\n    margin: 0;\n    padding: 0;\n    overflow: hidden;\n}\n\n@font-face {\n    font-family: Minecraft AE;\n    src: url(\"../../assets/fonts/Minecraft\\ AE.woff\") format(woff);\n    font-weight: 400;\n    font-style: normal;\n}\n\n* {\n    /* It is quite odd that if there's not the following declaration, the font won't be available in the canvas. */\n    /* So DO NOT DELETE THIS LINE */\n    font-family: Minecraft AE;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
