import { TextStyle } from "pixijs";
export const gameFont = "Minecraft AE";
export const CommonButtonStyle = {
    backgroundColor: 0x333333,
    borderColor: 0x636363,
    hoverBackgroundColor: 0x222222,
    hoverBorderColor: 0x43c91a
};
export const ButtonTextStyle = new TextStyle({
    fill: 0xeeeeee,
    fontSize: 20,
    fontFamily: gameFont,
});
export const PlainTextStyle = new TextStyle({
    fill: 0xffffff,
    fontSize: 16,
    fontFamily: gameFont
});
