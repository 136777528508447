import LoadingMenu from "./menus/LoadingMenu";
import MainMenu from "./menus/MainMenu";
import SavesMenu from "./menus/SavesMenu";
import SettingsMenu from "./menus/SettingsMenu";
import AboutMenu from "./menus/AboutMenu";
import InGameMenu from "./menus/InGameMenu";
import LoginMenu from "./menus/LoginMenu";
import Label from "./components/Label";
export default class Renderer {
    constructor(app) {
        this.app = app;
        this.menus = [
            new LoadingMenu(this),
            new MainMenu(this),
            new SavesMenu(this),
            new SettingsMenu(this),
            new AboutMenu(this),
            new InGameMenu(this),
            new LoginMenu(this), // 6
        ];
        this.initObjects();
    }
    initObjects() {
        this.app.stage.removeChild(this.fpsText);
        // FPS Text
        this.fpsText = new Label("", {
            x: 0,
            y: 15,
            style: {
                fill: 0xffffff,
                fontSize: 16,
            }
        });
        this.fpsText.position.x = window.innerWidth - 15 - this.fpsText.textObject.width;
        this.fpsText.appendTo(this.app.stage);
    }
    setMenu(menu, ...args) {
        this.currentMenu = menu;
        this.removeAllMenu();
        var menuContainer = this.menus[this.currentMenu];
        menuContainer.removeChildren();
        menuContainer.init(...args);
        menuContainer.appendTo(this.app.stage);
        this.initObjects();
    }
    removeAllMenu() {
        for (let i = 0; i < this.menus.length; i++) {
            this.menus[i].removeFrom(this.app.stage);
        }
    }
    update(delta) {
        this.menus[this.currentMenu].update(delta);
        this.fpsText.setText(`FPS: ${this.app.ticker.FPS.toFixed(1)} / ${this.app.ticker.maxFPS.toFixed(1)}`);
        this.fpsText.position.x = window.innerWidth - 15 - this.fpsText.textObject.width;
    }
}
