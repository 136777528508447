export default class Storage {
    constructor() {
        this.localStorage = window.localStorage;
    }
    static get() {
        if (!Storage.instance)
            Storage.instance = new Storage();
        return Storage.instance;
    }
    getItem(key, defaultValue) {
        var item = this.localStorage.getItem(key);
        if (!item) {
            if (!defaultValue)
                return null;
            this.setItem(key, defaultValue);
            return defaultValue;
        }
        return JSON.parse(item);
    }
    setItem(key, value) {
        this.localStorage.setItem(key, JSON.stringify(value));
    }
    removeItem(key) {
        this.localStorage.removeItem(key);
    }
    clear() {
        this.localStorage.clear();
    }
    get length() {
        return this.localStorage.length;
    }
}
